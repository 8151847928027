(function ($) {
    function tinSameHeight() {
        var tin = $(".tin-video-slider");
        tin.each(function () {
            $(this)
                .find("video")
                .on("loadeddata", function () {
                    setTimeout(tinSameHeight, 100);
                });
            $(this).find(".tin-video-slider--slide").css("height", "");
            var highest = 0;
            $.each($(this).find(".tin-video-slider--slide"), function () {
                //console.log($(this).height());
                if ($(this).height() > highest) {
                    highest = $(this).height();
                }
            });
            $(this).find(".tin-video-slider--slide").css("height", highest);
        });
    }

    $(document).ready(function () {
        // When new slides appears pause/play videos
        if (window.tinSlide) {
            window.tinSlide.on("itemSelected", function (event) {
                $(".tin-video-slider").find("video").get(0).pause();
                $(".tin-video-slider")
                    .find(".tin-slide-selected")
                    .find("video")
                    .get(0)
                    .play();
            });

        // Play the first video on page load
        $(".tin-video-slider")
            .find(".tin-slide-selected")
            .find("video")
            .get(0)
            .play();

        }

        setTimeout(tinSameHeight, 500);
        $(window).on("resize", function () {
            setTimeout(tinSameHeight, 500);
        });

    });
})(jQuery);
