$(document).ready(function() {

    $('.contact__filter select').change(function () {
        $select = $(this);
        $wrapper = $select.closest('.contacts-wrapper');
        filter = $select.data('filter');
        otherFilters = '';
        otherNotFilters = '';

        $wrapper.find('.contact__filter select').not(this).each(function () {
            $otherSelect = $(this);
            if($otherSelect.val() !== 'all') {
                otherFilters += '[data-'+$otherSelect.data('filter')+'*="'+$otherSelect.val()+'"]';
                otherNotFilters += '[data-'+$otherSelect.data('filter')+'*="'+$otherSelect.val()+'"],';
            }
        });

        otherNotFilters = otherNotFilters.slice(0, -1);

        if($select.val() === 'all') {
            $wrapper.find('.component-contactsingle').not(otherNotFilters).parent().hide();
            $wrapper.find('.component-contactsingle'+otherFilters).parent().show();
        } else {
            $wrapper.find('.component-contactsingle').not('[data-'+filter+'*="'+$select.val()+'"] '+(otherNotFilters === '' ? '' : otherNotFilters)).parent().hide();
            $wrapper.find('.component-contactsingle[data-'+filter+'*="'+$select.val()+'"]'+otherFilters).parent().show();
        }
    });

});