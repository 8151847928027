if(window.PostNotifications !== undefined) {

    var PostNotificationsInterface = function(blogId, newsLink, updateBlogPosts) {

        // Properties.
        this.blogId = blogId;
        this.readPosts = [];
        this.newPosts = [];
        this.storagePrefix = 'blog-'+blogId+'-';
        this.newsLink = newsLink;
        this.updateBlogPosts = updateBlogPosts;
        this.notifications = null;

        // Init method.
        this.init = function() {
            this.updateNotifications();
            var that = this;
            $.get(urls.ajaxurl, {
                action: 'new_posts',
                blog_id: this.blogId
            }, function(result) {
                if(result && result.newPosts && result.newPosts !== undefined) {
                    that.setNewPosts(result.newPosts);
                }
            });
        };
        this.setNewPosts = function(posts) {
            // Remove any posts already read.
            for(var i=0, len=this.readPosts.length; i<len; i++) {
                var idx = posts.indexOf(this.readPosts[i]);
                if(idx !== -1) {
                    posts.splice(idx, 1);
                }
            }
            this.newPosts = posts;
            this.saveMemory();
            this.updateNotifications();
        };
        this.updateNotifications = function() {
            // News link notification.
            var num = this.newPosts.length;
            this.notifications.text(num);
            if(num) {
                this.notifications.show();
            }
            else {
                this.notifications.hide();
            }
            if(this.updateBlogPosts) {
                // Unread posts notification.
                $('.post-notifications-new').removeClass('post-notifications-new');
                for(var i=0, len=this.newPosts.length; i<len; i++) {
                    $('.post-id-'+this.newPosts[i]).addClass('post-notifications-new');
                }
            }
        };
        this.setReadPost = function(id) {
            if(this.readPosts.indexOf(id) === -1) {
                this.readPosts.push(id);
            }
            var idx = this.newPosts.indexOf(id);
            if(idx !== -1) {
                this.newPosts.splice(idx, 1);
            }
            this.updateNotifications();
            this.saveMemory();
        };
        // Load from local storage.
        this.loadMemory = function() {
            var readPosts, newPosts;
            if(localStorage !== undefined) {
                readPosts = localStorage.getItem(this.storagePrefix+'readPosts');
                newPosts = localStorage.getItem(this.storagePrefix+'newPosts');
            }
            if(readPosts && readPosts !== undefined) {
                this.readPosts = JSON.parse(readPosts);
            }
            if(newPosts && newPosts !== undefined) {
                this.newPosts = JSON.parse(newPosts);
            }
        };
        // Save to local storage.
        this.saveMemory = function() {
            localStorage.setItem(this.storagePrefix+'newPosts', JSON.stringify(this.newPosts));
            localStorage.setItem(this.storagePrefix+'readPosts', JSON.stringify(this.readPosts));
        };

        /**
         *  Constructor.
         */
        var that = this;
        // Create notification elements.
        newsLink.each(function() {
            $(this).append('<span class="post-notifications-count" style="display:none;"></span>');
        });
        this.notifications = $('span.post-notifications-count', newsLink);
        // Load from memory.
        this.loadMemory();

    };

    /**
     *  Create notification interfaces for all blogs used on the website.
     */
    for(var key in PostNotifications.blogs) {
        var blogObject = PostNotifications.blogs[key];
        var newsLink = $(blogObject.newsLink);
        if(!newsLink.length) {
            newsLink = $('nav a[href="' + blogObject.newsUrl + '"]');
        }
        blogObject.interface = new PostNotificationsInterface(
            blogObject.blogId,
            newsLink,
            blogObject.updateBlogPosts
        );

        if(blogObject.postId) {
            blogObject.interface.setReadPost(blogObject.postId);
        }
        blogObject.interface.init();
    }

    /**
     *  Helper function to update all notifications.
     */
    PostNotifications.updateNotifications = function() {
        for(var key in PostNotifications.blogs) {
            var blogObject = PostNotifications.blogs[key];
            blogObject.interface.updateNotifications();
        }
    };

}
