$(document).ready(function() {
    var cookieElement = $('.component-cookiealert');
    var cookiesButton = $('.alert__acceptbutton');

    $.fn.checkLocalStorage = function() {
        try {
            localStorage.setItem('check', 'check');
            localStorage.removeItem('check');
            return true;
        } catch(e) {
            return false;
        }
    };

    $.fn.getCookieStatus = function() {
        return localStorage.getItem('cookie_accepted');
    };

    $.fn.setCookieStatus = function() {
        return localStorage.setItem('cookie_accepted', '1');
    };

    cookiesButton.on('click', function (e) {
        e.preventDefault();
        if ($(this).checkLocalStorage()) {
            $(this).setCookieStatus();
        }
        cookieElement.hide();
    });

    if ($(this).checkLocalStorage() && $(this).getCookieStatus()) {
        cookieElement.hide();
    } else {
        cookieElement.css('display', 'flex');
    }
});
