jQuery.expr[':'].contains = function(a, i, m) {
    return jQuery(a).text().toUpperCase()
    .indexOf(m[3].toUpperCase()) >= 0;
};

jQuery.expr[':'].hasFilter = function(a, i, m) {
    if (jQuery(a).data('filter') !== undefined ) {
        return jQuery(a).data('filter').indexOf(m[3] + ',') >= 0;
    } else {
        return false;
    }
};

jQuery(document).ready(function () {
    var $component = $('.component-downloadablefileswithtabs');
    var urlparams = new URLSearchParams(window.location.search);

    if (urlparams.get('filter') !== null) {
        var filter = urlparams.get('filter');
        $('.tab.active', $component).removeClass('active');
        $('.tab[data-tab="'+filter+'"]').addClass('active');

        $('.tab-files.active', $component).removeClass('active');
        $('.tab-files[data-tab="'+filter+'"]').addClass('active');
    }

    $('.tab', $component).click(function () {
        $('.tab.active', $component).removeClass('active');
        $(this).addClass('active');

        $('.tab-files.active', $component).removeClass('active');
        $('.tab-files[data-tab="'+$(this).data('tab')+'"]').addClass('active');
    });

    $('.filter', $component).change(function() {
        var val = $(this).val();

        var search = $('.search', $component).val().trim() !== '' ? ' a span:contains("' + $('.search', $component).val() + '")' : '';
        
        if (val === 'all') {
            $('.tab-files li' + search, $component).show();
        } else {
            $('.tab-files li', $component).hide();
            $('.tab-files li:hasFilter("'+val+'")' + search, $component).show();
        }
    });

    $('.search', $component).keyup(function() {
        var val = $(this).val();

        var filter = $('.filter', $component).val() !== 'all' ? ':hasFilter("' + $('.filter', $component).val() + '")' : '';

        $('.tab-files li', $component).hide();
        $('.tab-files li' + filter + ' a span:contains("'+val+'")', $component).closest('li').show();
    });
});