(function($) {

    function responsiveImage4_3() {
        $.each($('[data-resp-4-3]'), function() {
            var width = $(this).width();
            var height = (width/4) * 3;
            $(this).css('height', height);
        });
    }

    $(document).ready(function() {
        responsiveImage4_3();
        $(window).on('resize', responsiveImage4_3);
    });


    // smooth scroll anchor links
    $('a[href^="#"]').on('click', function(event) {
        var target = $( $(this).attr('href') );
        if ( target.length === 0) {
            var hash = $(this).attr('href').slice(1);
            target = $('.' + hash);
        }
        if( target.length ) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top - 120
            }, 500);
        }
    });

})(jQuery);
