
function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}


$('.ticker').each(function(){
    var el = $(this);

    // create a ticker on an element with options
    var number = el.attr('data-value');
    var tick = Tick.DOM.create(el[0], {
        value: number - Math.ceil(Math.random() * 3),
        didInit: function(tick) {

        }
    });

    var waypoint = new Waypoint({
        element: el[0],
        handler: function(direction) {
            if(direction === 'down'){
                setTimeout(function(){
                    tick.value = number;
                }, randomIntFromInterval(1, 1000));

            }
        },
        offset: 'bottom-in-view'
    });
});

