jQuery(document).ready(function ($) {
    $('.gfield_error .mdl-textfield').addClass('is-invalid');
});


jQuery(document).bind('gform_post_render', function () {

    $('.gform_fields input').each(function () {
        if ($(this).val()) {
            $(this).parent().parent().addClass('is-dirty');
        }
    });

    $('.gfield_error .mdl-textfield').addClass('is-invalid is-dirty');
});

