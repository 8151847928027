(function( $ ){

    function getParameterByName(name, url) {
        if (!url) { url = window.location.href; }
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) { return null; }
        if (!results[2]) { return ''; }
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    function initSelectsFromURL() {
        var cat = getParameterByName('cat');
        if(cat !== null && cat !== '') {
            $('#cat').val(cat);
        }
        var year = getParameterByName('year');
        if(year !== null && year !== '') {
            $('#year').val(year);
        }
    }
    function buildSearchQueryString() {
        var $cat = $('#cat');
        var $year = $('#year');

        var str = '';

        if ( $year.val() > 0 ) {
            str += '?year=' + $year.val();
        }
        if ( $cat.val() > 0 ) {
            str += str === '' ? '?' : '&';
            str += 'cat=' + $cat.val();
        }
        location.href = '/nyheter-press/'+str;
    }

    $(document).ready(function () {
        initSelectsFromURL();

        $('#cat').on('change', function () {
            buildSearchQueryString();
        });
        $('#year').on('change', function () {
            buildSearchQueryString();
        });
    });
})( jQuery );
