(function ($) {

    // Listens for params -> ?cat=slug
    $(document).ready(function () {
        var searchParams = new URLSearchParams(window.location.search);

        if (searchParams.has('cat')) {
            $param = searchParams.get('cat');
            $this = $('[name="' + $param + '"]');
            $categoryId = $this.attr('id');

            $('#element').filterByCategories($categoryId, $this);
            $("ul.imagebank-list").toggleClass('display-none display');
        }
    });

    $('button.category-dropdown').on('click', function () {
        $("ul.imagebank-list").toggleClass('display-none display');
    });

    $('li.list-item').on('click', function (event) {

        $this = $(this);
        $categoryId = event.target.id;
        $('#element').filterByCategories($categoryId, $this);

    });

    // filter by categories
    $.fn.filterByCategories = function ($categoryId, $this) {
        $("span.acitve-filter").text($this.text());
        $("ul.imagebank-list").toggleClass('display-none display');
        $("i.toggle-active").addClass("display-none");
        $this.find('i').removeClass("display-none");

        if ($categoryId === '0') {
            $(".img-info").each(function () {
                $(this).show();
            });

        } else {
            $(".img-info").each(function () {

                if ($(this).attr("value").includes($categoryId)) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        }
    };

    // filter on search 
    $("input.imagebank-input").on("keyup", function () {
        $(".imagebank-input").html("");
        var searchValue = $.trim(this.value).toLowerCase();

        $(".img-info").each(function () {
            var text = $.trim($(this).find('p').text()).toLowerCase();

            if (text.includes(searchValue) ) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });
    });

})(jQuery);