(function ($) {

    $('.meta .links').find('button').on('click', function() {
        var el = $(this);
        var view = el.data('view');
        $('.reference-overlay').addClass('active');
        $('.reference-overlay').find('.inner').removeClass('invert wide');
        $('.reference-overlay').find('.view').removeClass('active');
        $('.reference-overlay').find('.view.' + view).addClass('active');
        window.dispatchEvent(new Event('resize')); // For image lazyload

        if(view === 'slideshow') {
            $('.reference-overlay').find('.inner').addClass('wide');
        }

        setTimeout(function() {


            $('.reference-overlay').find('.slider-wrapper').each(function () {
                var el = $(this);
                el.slick('unslick');

                var fade = el.data('transition') === 'fade';
                var transitionDuration = fade ? 1000 : 300;

                el.slick({
                    dots: true,
                    speed: transitionDuration,
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    adaptiveHeight: true,
                    fade: fade,
                });
            });
        }, 500);

        return false;
    });

    $('.reference-overlay').find('.close').on('click', function() {
        $(this).closest('.reference-overlay').removeClass('active');
        return false;
    });

    // Contact btn
    $('.contact-expert').find('a.btn').on('click', function() {
        $('.reference-overlay').addClass('active');
        $('.reference-overlay').find('.inner').addClass('invert wide');
        $('.reference-overlay').find('.view').removeClass('active');
        $('.reference-overlay').find('.view.contact').addClass('active');
        return false;
    });

    // Blueprint overlay trigger
    $('.download-blueprint').find('a.btn').on('click', function() {
        var el = $(this);
        $('.reference-overlay').addClass('active');
        $('.reference-overlay').find('.inner').removeClass('invert wide');
        $('.reference-overlay').find('.view').removeClass('active');
        $('.reference-overlay').find('.view.blueprint').addClass('active');
        window.dispatchEvent(new Event('resize')); // For image lazyload
        return false;
    });

    // Blueprint show form button
    $('.reference-overlay').find('.view.blueprint a.btn').on('click', function() {

        var view = $(this).closest('.view');
        view.find('.form').slideDown('fast');
        view.find('a.btn').hide();

        var fileId = view.find('.form').attr('data-file');

        $('.view.blueprint form .input-acfattachedfile').find('input').val(fileId);
        return false;
    });


})(jQuery);
