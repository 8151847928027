(function( $ ){
    function animateSubMenu (el) {
        var $el = $(el);

        var width = $el.outerWidth();

        var $floatingEl = $('.sub-menu .floating-bg');

        $floatingEl.css({
            'width': width,
            'left': $el.offset().left - $el.parent().offset().left
        });

        $('.bg-active').removeClass('bg-active');
        $el.addClass('bg-active');
    }
    $(document).ready(function () {
        $('.primary-nav-sub-menu .page-name').click(function (e) {
            $('html, body').animate({scrollTop: 0}, 400, 'swing');
        });

        if($('.sub-menu.nav').length > 0) {
            $('body').addClass('has-sub-menu');
        }

        if($('.sub-menu.nav li.active').length > 0) {
            animateSubMenu($('.sub-menu.nav li.active'));
        }

        $('.sub-menu.nav li').hover(function () {
            animateSubMenu(this);
        });

        $('.component-header .toggle-search').click(function (e) {
            $(this).parent().find('form').toggleClass('show-search');
            if($(this).parent().find('form').hasClass('show-search')) {
                $(this).parent().find('form input').focus();
            } else {
                $(this).parent().find('form input').blur();
            }

        });


        /* SUB MENU */
        var currentHash = $('.primary-nav-sub-menu .sub-menu ul li a').length > 0 ? $('.primary-nav-sub-menu .sub-menu ul li a').first().prop('hash').slice(1) : '';
        var hashClick = false;
        var autoScroll = true;

        function scrollToUrl(isFirst) {
            var timeout = isFirst ? 300 : 0;
            autoScroll = true;

            var hash = window.location.hash.slice(1);
            currentHash = hash;

            if(hash !== undefined && hash !== null && hash !== '') {

                setTimeout(function () {
                    $('.primary-nav-sub-menu .sub-menu li a').each(function () {
                        if($(this).attr('href').indexOf(hash) > 0) {
                            animateSubMenu($(this).parent());
                        }
                    });

                    $('html, body').animate({
                        scrollTop: jQuery('.' + hash).offset().top - 140
                    }, 400, 'swing', function () {
                        hashClick = false;
                        setTimeout(function () {
                            autoScroll = false;
                        }, 100);
                    });
                }, timeout);

            } else {
                autoScroll = false;
            }
        }

        $(document).scroll(function () {
            if(!hashClick && !autoScroll) {
                $('.primary-nav-sub-menu .sub-menu li a').each(function () {
                    var hash = $(this).prop('hash').slice(1);
                    var top = window.pageYOffset + (window.innerHeight / 2);
                    var distance = top - ($('.' + hash).offset().top + $('.' + hash).height());

                    if (distance < 400 && distance > -400 && currentHash !== hash) {
                        history.replaceState(undefined, undefined, "#" + hash);
                        currentHash = hash;

                        animateSubMenu($(this).parent());
                    }
                });
            }
        });

        $('.primary-nav-sub-menu .sub-menu ul li a').click(function (e) {
            e.preventDefault();
            history.replaceState(undefined, undefined, $(this).attr('href'));
            scrollToUrl(false);
        });

        $(window).bind('hashchange', function () {
            if(hashClick) {
                scrollToUrl(false);
            }
        });

        $('.component-mobilemenu nav.sub-nav li a').click(function () {
            hashClick = true;
            $('#toggle-nav').click();
        });

        $('.sub-menu li a').click(function (e) {
            hashClick = true;
        });

        scrollToUrl(true);

        if($('.primary-nav-sub-menu .sub-menu ul li a').length > 0) {
            animateSubMenu($('.primary-nav-sub-menu .sub-menu ul li').first());
        }

    });
})( jQuery );

(function() {

    var $window = $(window);
    var $header = $('.component-header');
    var isScrolled = false;
    var ival = setInterval(function() {

        if($window.scrollTop() > 100) {
            if(!isScrolled) {
                isScrolled = true;
                $header.addClass('pinned');
            }
        }
        else {
            if(isScrolled) {
                isScrolled = false;
                $header.removeClass('pinned');
            }
        }

    }, 100);

})();
