/**
 *  Initialize maps.
 */
window.initOfficeMaps = function() {

    $('.component-office .map-inner').each(function() {

        var $map = $(this).parent();

        /**
         *  Marker location.
         */
        var lat = $(this).data('lat');
        var lng = $(this).data('lng');

        /**
         *  Create styled map.
         */
        var map = new google.maps.Map(this, {
            zoom: 14,
            center: new google.maps.LatLng(lat, lng),
            mapTypeId: 'roadmap',
            minZoom: 5,
            maxZoom: 18,
            scrollwheel: false,
            draggable: true,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
        });

        /**
         *  Add marker.
         */
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            map: map
        });

        /**
         *  Re-center map on window resize. Also set map ratio depending on map size.
         */
        function resized() {
            var w = $map.width();
            if(w < 200) {
                $map.addClass('small');
            }
            else {
                $map.removeClass('small');
            }
            map.setCenter(new google.maps.LatLng(lat, lng));
        }
        $(window).resize(function() {
            resized();
        });
        resized();

    });
};
