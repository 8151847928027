$(document).ready(function() {
    $(document).on('click', '.event-term', function (event) {
        event.preventDefault();
        $('.is-active').removeClass('is-active');
        $(this).addClass('is-active');

        $('.events-slider-items').slick('slickUnfilter');
        $('.events-slider-items').slick('slickFilter', '.' + $(this).attr('data-term'));

        $.fn.reloadSlick();
    });

    $(window).on('resize', function(){
          $.fn.reloadSlick();
    });

    $.fn.extend({
        reloadSlick: function() {
            slickSlides = $.fn.getDefaultSlides();
            $('.events-slider-items').slick('slickSetOption', 'slidesToShow', slickSlides);
            if($('.events-slider-items').length) {
                $('.events-slider-items')[0].slick.refresh();
            }
        },
        loadSlick: function() {
            defaultSlidesToShow = $.fn.getDefaultSlides();

            $('.events-slider-items').slick({
                infinite: false,
                slidesToShow: defaultSlidesToShow,
                slidesToScroll: 1,
                speed: 200,
                responsive: [
                    {
                        breakpoint: 2000,
                        settings: {
                            slidesToShow: defaultSlidesToShow,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        },
        getDefaultSlides: function() {
            slickSlides = $('.slick-track').children('.event-wrapper').length;

            if (slickSlides === 0)  {
                slickSlides = 3;
            }

            if (window.innerWidth < 992 && window.innerWidth > 767) {
                slickDefault = 2;
            }
            else if (window.innerWidth < 768) {
                slickDefault = 1;
            }
            else {
                slickDefault = 3;
            }

            slickSlides = (slickSlides > slickDefault) ? slickDefault : slickSlides;

            return slickSlides;
        }
    });

    $.fn.loadSlick();
});
