$('.slider-wrapper').each(function() {
    var el = $(this);

    var fade = el.data('transition') === 'fade';
    var transitionDuration = fade ? 1000 : 300;

    el.slick({
        dots: true,
        speed: transitionDuration,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        adaptiveHeight: true,
        fade: fade,
    });



});
