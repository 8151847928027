(function( $ ){
    $(document).ready(function () {
        $('#toggle-nav').click(function () {
            $(this).toggleClass('open');
            $(this).find('.closed').toggle();
            if ($(this).find('.open').is(':hidden')) {
                $(this).find('.open').css('display','inline-block');
            }
            else {
                $(this).find('.open').css('display','none');
            }
            $('.component-header').toggleClass('show-nav');
        });
        $('.main-nav .expand-menu').click(function (e) {
            e.preventDefault();
            $(this).toggleClass('expanded');
            $(this).parent().find('a').toggleClass('expanded-menu');
            $(this).parent().find('.sub-nav ul').slideToggle(300);
        });
        $('.active, .active-parent, .active-ancestor', $('.component-mobilemenu')).find('.expand-menu').click();
    });
})( jQuery );
