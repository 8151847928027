Vue.component('slider', {
    template: '#slider',
    props: {
        property: String, 
        data: Object, 
    },
    methods: {
        updateRange: function (){
            this.$emit('updatevalue', 
                this.property, this.data.value
            );
        }, 
    }, 
});
