$('.component-slider').each(function() {
    $(this).slick({
        // dots: true,
        speed: 300,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        adaptiveHeight: false,
        dots: true
    });
    // Pause video when sliding.
    $(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $('.slide.video', this).each(function() {
            if(this.videoYouTube !== undefined) {
                this.videoYouTube.pauseVideo();
            }
        });
    });
});

$('.component-slider .icon-double-down').click(function () {
    var $slider = $(this).closest('.component-slider');

    $('html, body').animate({
        scrollTop: $slider.offset().top + $slider.height() - 60
    }, 'slow');

    return false;
});


/**
 *  YouTube video functionality.
 */
var Slider = {};
Slider.youTubeAPI = {

    apiInjected: false,
    apiReady: false,

    // Inject Youtube API.
    inject: function() {
        if(!this.apiInjected) {
            this.apiInjected = true;
            // Inject the script tag.
            var tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            // Handler to invoke when API is ready.
            var that = this;
            window.onYouTubeIframeAPIReady = function() {
                that.onYouTubeIframeAPIReady();
            };
        }
    },
    // Handler when API is ready.
    onYouTubeIframeAPIReady: function() {
        this.apiReady = true;
        $(this).trigger('ready');
    }

};

/**
 *  YouTube component. Takes video url or id, and target element ID.
 *  Loads YouTube API and replaces element with a controllable chromeless video player.
 */
Slider.videoYouTube = function(elementOrId, urlOrVideoId) {

    this.elementOrId = elementOrId;
    this.videoId = null;
    this.player = null;

    /**
     *  Extract video id from url.
     */
    var regex = /v=([^&]+)/i;
    var match = regex.exec(urlOrVideoId);
    if(match && match.length > 1) {
        this.videoId = match[1];
    }
    else {
        this.videoId = urlOrVideoId;
    }

    /**
     *  Load youtube API.
     */
    if(this.videoId) {

        // Inject YouTube API if necessary.
        Slider.youTubeAPI.inject();

        // If API is ready, create video. If not – register listener.
        if(Slider.youTubeAPI.apiReady) {
            this.onYouTubeIframeAPIReady();
        }
        else {
            var that = this;
            $(Slider.youTubeAPI).on('ready', function() {
                that.onYouTubeIframeAPIReady();
            });
        }

    }
    /**
     *  Youtube API loaded – create video player and play video.
     */
    this.onYouTubeIframeAPIReady = function() {

        this.player = new YT.Player(this.elementOrId, {

            height: '100%',
            width: '100%',
            videoId: this.videoId,

            /**
             *  Specify player parameters with minimal graphics.
             */
            playerVars: {
                controls: 0,        // No playback interface.
                disablekb: 1,       // Disable keyboard controls.
                enablejsapi: 1,     // Enable JS API.
                fs: 0,              // No full screen button.
                loop: 1,            // Loop.
                playlist: this.videoId, // Required for looping single video to work.
                modestbranding: 1,  // Minimal YouTube branding.
                rel: 0,             // No related content after video.
                showinfo: 0,        // No video info.
                autoplay: 1,        // Autoplay – no video image.
                iv_load_policy: 3,  // No video annotations.
                playsinline: 1,     // No full screen playback on iOS.
                origin: undefined   // Specify domain for improved security. Auto injected by API.
            },

            events: {
                'onReady': this.onPlayerReady,
                'onStateChange': this.onPlayerStateChange
            }

        });
    };
    this.onPlayerReady = function(event) {
        // event.target.mute();
        event.target.playVideo();
    };
    this.onPlayerStateChange = function(event) {

    };
    this.pauseVideo = function() {
        if(this.player) {
            this.player.pauseVideo();
        }
    };
    this.playVideo = function() {
        if(this.player) {
            this.player.playVideo();
        }
    };
    this.rewind = function() {
        if(this.player) {
            this.player.seekTo(0);
        }
    };
};

/**
 *  Set up video functionality for all video slides.
 */
$('.component-slider .slide.video').each(function() {
    var $player = $('.youtube-slide-player', this);
    var that = this;
    $('.play-button', this).click(function() {
        $(that).addClass('video-on');
        if(that.videoYouTube === undefined) {
            that.videoYouTube = new Slider.videoYouTube($player.get(0), $player.data('url'));
        }
        else {
            that.videoYouTube.playVideo();
        }
    });
    $('.youtube-slide-close', this).click(function() {
        if(that.videoYouTube !== undefined) {
            that.videoYouTube.pauseVideo();
            that.videoYouTube.rewind();
        }
        $(that).removeClass('video-on');
    });
});
