(function ($) {
    var $grid = $('.component-referencelisting').find('.listing');
    $grid.isotope({
        itemSelector: '.post-slider__post',
        layoutMode: 'fitRows'
    });

    $('button.filter-btn').click( function (e) {
        var el = parseInt(this.value);

        $grid.isotope({
            filter: function() {
                if(el === 0){
                    return true;
                }
                var types = $(this).attr('data-type').split(',').map(Number);
                if($.inArray(el,types) !== -1){
                    return true;
                }
                return false;
            }
         });

         $('button.filter-btn.active ').removeClass('active');
    });

})(jQuery);
