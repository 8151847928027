if($('.hot-spot-app').length){
    var app = new Vue({
        el: '.hot-spot-app',
        data: {
            spot: {
                number: '',
                title: '',
                text: '',
            }
        },
        methods: {
            updateSpot: function(num) {
                app.spot.number = hotspot_arr[num].number;
                app.spot.title = hotspot_arr[num].title;
                app.spot.text = hotspot_arr[num].text;

                $('.spot').removeClass('active');
                $('.spot-'+num).addClass('active');
            }
        },
        mounted: function () {
            // Activate first from start
            this.$nextTick(function () {
                this.updateSpot(0);
            });
        }
    });

}