(function () {
    function createDynamakerApp() {
        $("#dynamaker-app").each(function () {
            var dynamakerApp = new Vue({
                el: "#dynamaker-app",
                data: {
                    config: {
                        url: "",
                        saveUrl: "",
                    },
                    data: {
                        openOfficeSeats: {
                            min: 0,
                            max: 56,
                            steps: 8,
                            value: 8,
                            param: 1,
                        },
                        privateOfficesSize10: {
                            min: 0,
                            max: 20,
                            steps: 2,
                            value: 0,
                            param: 2,
                        },
                        privateOfficesSize20: {
                            min: 0,
                            max: 20,
                            steps: 2,
                            value: 0,
                            param: 3,
                        },
                        conferenceRoomsSeats4: {
                            min: 0,
                            max: 6,
                            steps: 2,
                            value: 0,
                            param: 4,
                        },
                        conferenceRoomsSeats10: {
                            min: 0,
                            max: 6,
                            steps: 2,
                            value: 0,
                            param: 5,
                        },
                        conferenceRoomsSeats20: {
                            min: 0,
                            max: 6,
                            steps: 2,
                            value: 0,
                            param: 6,
                        },
                        pauseRoomSeats8: {
                            min: 0,
                            max: 3,
                            steps: 1,
                            value: 0,
                            param: 7,
                        },
                        pauseRoomSeats24: {
                            min: 0,
                            max: 3,
                            steps: 1,
                            value: 0,
                            param: 8,
                        },
                        stairWellPlacement: {
                            min: 0,
                            max: 100,
                            steps: 1,
                            value: 50,
                            param: 9,
                        },
                        furnitureToggle: {
                            value: false,
                            param: 10,
                        },
                        levels: {
                            value: false,
                            param: 11,
                        },
                    },
                    activePropertyView: "showOfficeSpaces",
                    showHoverText: false,
                    disableLevelsToggle: false,
                    activeView: 1,
                    modules: "",
                    modulesData: {},
                    area: "",
                    cost: "",
                    prices: window.data.price,
                    formId: window.data.formId,
                    submitbuttonText: window.data.submitbutton,
                    inputs: {
                        link: "input_" + window.data.formId + "_4",
                        modules: "input_" + window.data.formId + "_7",
                        area: "input_" + window.data.formId + "_8",
                        cost: "input_" + window.data.formId + "_9",
                        image: "input_" + window.data.formId + "_5",
                        layout: "input_" + window.data.formId + "_6",
                    },
                    submitButtonId: "gform_submit_button_" + window.data.formId,
                },
                watch: {
                    // openOfficeSeats has value 4, 8, 16, 24 ...
                    "data.openOfficeSeats.value": function () {
                        if (this.data.openOfficeSeats.value < 8) {
                            this.data.openOfficeSeats.steps = 4;
                        } else {
                            this.data.openOfficeSeats.steps = 8;
                        }
                    },
                },
                methods: {
                    setActivePropertyView: function (value) {
                        this.activePropertyView = value;
                    },
                    updateAllData: function () {
                        var levelsValue = this.convertLevelsValue(
                            this.data.levels.value
                        );

                        document
                            .getElementById("dynamakeriframe")
                            .contentWindow.postMessage(
                                JSON.stringify({
                                    properties: {
                                        openOfficeSeats:
                                            this.data.openOfficeSeats.value,
                                        privateOfficesSize10:
                                            this.data.privateOfficesSize10
                                                .value,
                                        privateOfficesSize20:
                                            this.data.privateOfficesSize20
                                                .value,
                                        conferenceRoomsSeats4:
                                            this.data.conferenceRoomsSeats4
                                                .value,
                                        conferenceRoomsSeats10:
                                            this.data.conferenceRoomsSeats10
                                                .value,
                                        conferenceRoomsSeats20:
                                            this.data.conferenceRoomsSeats20
                                                .value,
                                        pauseRoomSeats8:
                                            this.data.pauseRoomSeats8.value,
                                        pauseRoomSeats24:
                                            this.data.pauseRoomSeats24.value,
                                        stairWellPlacement:
                                            this.data.stairWellPlacement.value,
                                        levels: levelsValue,
                                    },
                                    setttings: {
                                        furnitureToggle:
                                            this.data.furnitureToggle.value,
                                    },
                                }),
                                "*"
                            );

                        this.getMetricsData();
                    },
                    updateProperty: function (property, value, updateUrl) {
                        if (property === "levels") {
                            value = this.convertLevelsValue(value);
                        }

                        var propertyArray = {};
                        propertyArray[property] = value;

                        document
                            .getElementById("dynamakeriframe")
                            .contentWindow.postMessage(
                                JSON.stringify({ properties: propertyArray }),
                                "*"
                            );
                        this.getMetricsData();

                        if (!updateUrl) {
                            this.updateUrl();
                            localStorage.setItem(
                                "configurator",
                                this.config.saveUrl
                            );
                        }
                    },
                    updateSetting: function (property, value) {
                        if (property === "viewMode") {
                            value = parseInt(value);
                        }

                        var settingArray = {};
                        settingArray[property] = value;

                        document
                            .getElementById("dynamakeriframe")
                            .contentWindow.postMessage(
                                JSON.stringify({ settings: settingArray }),
                                "*"
                            );
                    },
                    setTab: function (tab) {
                        this.updateSetting("viewMode", tab);
                        this.activeView = tab;
                    },
                    convertLevelsValue: function (value) {
                        if (value) {
                            return 2;
                        } else {
                            return 1;
                        }
                    },
                    createUrl: function () {
                        var params = "";

                        for (var key in this.data) {
                            var obj = this.data[key];
                            var param = obj.param + "=" + obj.value + "&";
                            params = params + param;
                        }

                        this.config.url =
                            window.location.protocol +
                            "//" +
                            window.location.hostname +
                            window.location.pathname +
                            "?" +
                            params;
                    },
                    updateUrl: function () {
                        var params = "";

                        for (var key in this.data) {
                            var obj = this.data[key];
                            var param = obj.param + "=" + obj.value + "&";
                            params = params + param;
                        }

                        this.config.saveUrl = params;
                    },
                    getCurrentConfig: function () {
                        document.querySelector(
                            "#" + this.submitButtonId
                        ).value = "";
                        document
                            .querySelector("#" + this.submitButtonId)
                            .classList.add("overlay-spinner");
                        document.querySelector(".spinner").style.display =
                            "block";
                        document.querySelector("body").style.overflow =
                            "hidden";

                        document.getElementById(
                            "dynamaker-form"
                        ).style.display = "block";

                        document
                            .querySelector("button#dynamaker-close-overlay")
                            .addEventListener("click", function () {
                                document.getElementById(
                                    "dynamaker-form"
                                ).style.display = "none";
                                document.querySelector("body").style.overflow =
                                    "auto";
                            });

                        this.createUrl();

                        var price = this.calculatePrice();

                        setTimeout(
                            function () {
                                document.getElementById(
                                    this.inputs.link
                                ).value = this.config.url;
                                document.getElementById(
                                    this.inputs.modules
                                ).value = this.modules;
                                document.getElementById(
                                    this.inputs.area
                                ).value = this.area;
                                document.getElementById(
                                    this.inputs.cost
                                ).value = price;
                            }.bind(this),
                            0
                        );

                        this.getLayout();
                    },
                    goHome: function () {
                        this.updateSetting("cameraHome", true);
                    },
                    dynamakerLoaded: function () {
                        if (
                            window.location.href.indexOf("?") !== -1 ||
                            localStorage.getItem("configurator")
                        ) {
                            this.updateAllData();
                        } else {
                            this.getMetricsData();
                        }
                    },
                    getMetricsData: function () {
                        document
                            .getElementById("dynamakeriframe")
                            .contentWindow.postMessage(
                                JSON.stringify({ getData: "metrics" }),
                                "*"
                            );
                        window.addEventListener(
                            "message",
                            this.updateReceivedData,
                            false
                        );
                    },
                    getImages: function () {
                        document
                            .getElementById("dynamakeriframe")
                            .contentWindow.postMessage(
                                JSON.stringify({ getData: "image" }),
                                "*"
                            );
                        window.addEventListener(
                            "message",
                            this.updateReceivedData,
                            false
                        );
                    },
                    getLayout: function () {
                        document
                            .getElementById("dynamakeriframe")
                            .contentWindow.postMessage(
                                JSON.stringify({ getData: "layout" }),
                                "*"
                            );
                        window.addEventListener(
                            "message",
                            this.updateReceivedData,
                            false
                        );
                    },
                    updateReceivedData: function (event) {
                        if (event.origin === "https://deployed.dynamaker.com") {
                            var data = JSON.parse(event.data);

                            if (data.dataType === "layout") {
                                document.getElementById(
                                    this.inputs.layout
                                ).value = data.data;
                                // trigger one at a time
                                this.getImages();
                            }

                            if (data.dataType === "image") {
                                document.getElementById(
                                    this.inputs.image
                                ).value = data.data;

                                if (
                                    document.getElementById(this.inputs.image)
                                        .value !== "" &&
                                    document.getElementById(this.inputs.layout)
                                        .value !== ""
                                ) {
                                    document
                                        .querySelector(
                                            "#" + this.submitButtonId
                                        )
                                        .classList.remove("overlay-spinner");
                                    document.querySelector(
                                        ".spinner"
                                    ).style.display = "none";
                                    document.querySelector(
                                        "#" + this.submitButtonId
                                    ).value = this.submitbuttonText;
                                }
                            }

                            if (data.dataType === "metrics") {
                                this.updateMetrixData(data);
                            }
                        }
                    },
                    calculatePrice: function () {
                        if (!this.prices || !this.modulesData) {
                            return;
                        }

                        // all prices data
                        var aModulePrice = parseInt(this.prices.a_module_prices) ? parseInt(this.prices.a_module_prices) : 0; // A-mudules
                        var bModulePrice = parseInt(this.prices.b_module_prices) ? parseInt(this.prices.b_module_prices) : 0; // B-mudules
                        var cModulePrice = parseInt(this.prices.c_module_prices) ? parseInt(this.prices.c_module_prices) : 0; // C-mudules
                        var dModulePrice = parseInt(this.prices.d_module_prices) ? parseInt(this.prices.d_module_prices) : 0; // D-mudules
                        var tModulePrice = parseInt(this.prices.t_module_prices) ? parseInt(this.prices.t_module_prices) : 0; // Stairs
                        var exteriorWallsLevelOnePrice = parseInt(this.prices.exterior_walls_level_1) ? parseInt(this.prices.exterior_walls_level_1) : 0; // exterior walls for 1 level
                        var exteriorWallsLevelTwoPrice = parseInt(this.prices.exterior_walls_level_2) ? parseInt(this.prices.exterior_walls_level_2) : 0; // exterior walls for 2 levels
                        var pentryPrice = parseInt(this.prices.pentry) ? parseInt(this.prices.pentry) : 0; // Pentry if pauseroom 24 people is selected
                        var ventilationConf20 = parseInt(this.prices.ventilation_conf20) ? parseInt(this.prices.ventilation_conf20) : 0; // Ventilation if conf 20 people is selected
                        var roundUpValue = parseInt(this.prices.round_up_total_price_to) ? parseInt(this.prices.round_up_total_price_to) : 0; // Route up value to 10 or 100

                        // calcuate all modules price
                        var totalPrice =
                            this.modulesData.A * aModulePrice +
                            this.modulesData.B * bModulePrice +
                            this.modulesData.C * cModulePrice +
                            this.modulesData.D * dModulePrice;

                        if (this.modulesData.T === 0) {
                            // 1 level
                            totalPrice += exteriorWallsLevelOnePrice;
                        } else {
                            // 2 levels
                            totalPrice +=
                                tModulePrice + exteriorWallsLevelTwoPrice;
                        }

                        // add the price for pentry if pauseroom 24 people is selected
                        if (this.data.pauseRoomSeats24.value > 0) {
                            totalPrice +=
                                pentryPrice * this.data.pauseRoomSeats24.value;
                        }

                        // add the price for ventilation if conference room 20 people is selected
                        if (this.data.conferenceRoomsSeats20.value > 0) {
                            totalPrice +=
                                ventilationConf20 *
                                this.data.conferenceRoomsSeats20.value;
                        }

                        // Round up the price to closest 100 or 10 depending on selected value in roundUpValue
                        totalPrice =
                            Math.round(totalPrice / roundUpValue) *
                            roundUpValue;

                        return totalPrice;
                    },
                    updateMetrixData: function await(data) {
                        if (data.data.area) {
                            var convertToMeters = data.data.area / 1000000;
                            this.area = convertToMeters.toFixed();
                        }
                        if (data.data.cost) {
                            this.cost = this.calculatePrice();
                        }

                        if (data.data.moduleCount) {
                            this.modulesData = data.data.moduleCount;

                            this.modules =
                                data.data.moduleCount.A +
                                data.data.moduleCount.B +
                                data.data.moduleCount.C +
                                data.data.moduleCount.D +
                                data.data.moduleCount.T;

                            if (this.modules >= 8) {
                                this.disableLevelsToggle = false;
                            } else {
                                if (this.disableLevelsToggle === false) {
                                    this.disableLevelsToggle = true;
                                    this.data.levels.value = false;
                                    this.updateProperty("levels", false, false);
                                }
                            }
                        }
                    },
                },
                created: function () {
                    var params = [];

                    if (window.location.href.indexOf("?") !== -1) {
                        var withoutURL = window.location.href.split("?")[1];
                        params = withoutURL.split("&");
                    } else if (localStorage.getItem("configurator")) {
                        var urlData = localStorage.getItem("configurator");
                        var seperatedData = urlData.split("&");
                        params = seperatedData.filter(function (element) {
                            if (element === "") {
                                return false;
                            } else {
                                return true;
                            }
                        });
                    }

                    if (params.length > 0) {
                        for (var key in params) {
                            var splitParams = params[key].split("=");
                            var propertyFromParam = JSON.parse(splitParams[0]);
                            var valueFromParam = JSON.parse(splitParams[1]);

                            for (var i in this.data) {
                                var data = this.data[i];

                                if (propertyFromParam === data.param) {
                                    data.value = valueFromParam;
                                }
                            }
                        }
                    }
                },
            });
        });
    }

    const createDynamakerAppTimer = setInterval(function () {
        if (!window.Vue) {
            return;
        }

        clearInterval(createDynamakerAppTimer);
        createDynamakerApp();
    }, 200);
})();
