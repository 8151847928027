(function ($) {
    function checkScroll(el) {
        if (el.find('.menu').scrollLeft() < 2) {
            el.find('.container').addClass('overflowing-right');
            el.find('.container').removeClass('overflowing');
        } else {
            el.find('.container').removeClass('overflowing-right');
            el.find('.container').addClass('overflowing');
        }
    }

    function checkOverflow() {
        $('.component-row').find('.menu-container').each(function () {
            var el = $(this);
            var w = 0;

            el.find('li').each(function () {
                var li = $(this);
                w += li.outerWidth() + 2;
            });

            if (el.outerWidth() < w) {
                checkScroll(el);
            } else {
                el.find('.container').removeClass('overflowing');
                el.find('.container').removeClass('overflowing-right');
            }

            el.find('.menu').scroll(function () {
                checkScroll(el);
            });

        });
    }

    checkOverflow();

    $(window).resize(function () {
        checkOverflow();
    });
}(jQuery));
