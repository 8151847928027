
$('#dynamaker-summary').each(function () {
    var dynamakerSummary = new Vue({
        el: '#dynamaker-summary',
        data: {
        },
        methods: {
        },
        created: function () {
            window.location.hash = '';
        },
    });
});


