(function () {
    var openSubMenuLi = $('.component-sidemenu li.sub-menu-on');
    $('.component-sidemenu .icon').click(function (event) {
        var li = $(this).closest('li');
        // Check if item has sub menu.
        if ($('.sub-menu', li).length) {
            event.preventDefault();
            li.toggleClass('sub-menu-on');
            if (li.hasClass('sub-menu-on')) {
                if (openSubMenuLi && openSubMenuLi.length) {
                    openSubMenuLi.removeClass('sub-menu-on');
                }
                openSubMenuLi = li;
            }
            else {
                openSubMenuLi = null;
            }
        }
    });

    /**
     *  Calculate sub menu heights.
     */
    var styles = {};
    var calculateSubMenuHeights = function () {
        $('.component-sidemenu .sub-menu').each(function () {
            var li = $(this).closest('li');
            var id = li.attr('id');
            // Create style tag.
            if (styles[id] === undefined) {
                styles[id] = $('<style></style>');
                $('head').append(styles[id]);
            }
            // Calculate menu height and save style.
            $t = $(this);
            $t.css({
                "max-height": "none",
                "visibility": "hidden",
                "transition": "none",
                "-webkit-transition": "none",
                "-moz-transition": "none",
                "-o-transition": "none",
                "-ms-transition": "none"
            });
            var h = $t.height();
            $t.attr('style', '');
            styles[id].text('.component-sidemenu li#' + id + '.sub-menu-on .sub-menu {max-height: ' + h + 'px;}');
        });
    };
    calculateSubMenuHeights();
    $(window).resize(function () {
        calculateSubMenuHeights();
    });

})();

/**
 *  Sticky SideMenu.
 */
var SideMenu = {};
SideMenu.sticky = function ($element, $constrainingElement) {

    this.$element = $element;
    this.$parent = $element.parent();
    this.$constrainingElement = $constrainingElement;
    this.isSticky = false;

    this.update = function () {

        if ($(window).width() >= 992) {
            var scrollTop = $(window).scrollTop();
            var marginTop = 30 + 5;
            var top = this.$parent.offset().top;
            var constrainingBottom = this.$constrainingElement.offset().top + this.$constrainingElement.height();
            var topMax = constrainingBottom - (marginTop + top + this.$element.outerHeight());

            if (topMax > 0) {
                if (top < scrollTop + marginTop) {
                    if (!this.isSticky) {
                        this.isSticky = true;
                        this.$element.css({
                            position: 'absolute',
                            left: 0,
                            top: 5,
                            width: '100%',
                            paddingLeft: '15px',
                            paddingRight: '15px'
                        });
                    }
                    var elementTop = (scrollTop + marginTop) - top;
                    if (elementTop > topMax) {
                        elementTop = topMax;
                    }
                    this.$element.css({
                        'top': elementTop
                    });
                }
                else {
                    if (this.isSticky) {
                        this.isSticky = false;
                        this.$element.attr('style', '');
                    }
                }
            }
        }
        else {
            if (this.isSticky) {
                this.isSticky = false;
                this.$element.attr('style', '');
            }
        }
    };

    var that = this;
    $(window).on('scroll resize touchstart touchend touchmove', function () {
        that.update();
    });
    this.update();

};

(function () {
    if ($('.component-sidemenu > .inner').length) {
        var sticky = new SideMenu.sticky($('.component-sidemenu > .inner'), $('.main-content'));
    }
})();
