$(document).ready(function () {

    $('.component-priceconfigurator').each(function () {
        var el = $(this);
        var result = el.find('.result-container');
        var resultOuter = $('.price-calc-result');

        $(this).parent().closest('.component-row').css("z-index", "9999");

        // help function to get closest match
        function getClosest(num, unit, arr, toggle) {

            var index = 0;
            var curr = 0;

            if (unit === 'm2') {
                curr = arr[0].m2;
                var diff = Math.abs(num - curr);
                for (var val = 0; val < arr.length; val++) {
                    var newdiff = Math.abs(num - arr[val].m2);
                    if (newdiff < diff) {
                        diff = newdiff;
                        curr = arr[val].m2;
                        index = val;
                    }
                }

                var mediumSeats = parseFloat(arr[index].seats_min) + Math.floor(((parseFloat(arr[index].seats_max) - parseFloat(arr[index].seats_min)) / 2));
                el.find('.calc-wrapper').find('.seats .val').text(mediumSeats);

            }
            if (unit === 'seats') {
                //console.log('NUM:' + num);
                for (var i = 0; i < arr.length; i++) {
                    if (num > arr[i].seats_max) {
                        if (i === arr.length - 1) {
                            index = i;
                        }
                        index = i;
                    } else {
                        index = i;
                        break;
                    }
                }

                el.find('.calc-wrapper').find('.m2 .val').text(arr[index].m2);
            }

            if (toggle) {
                return false;
            }


            // populate
            result.find('.m2').text(arr[index].m2);
            result.find('.input-m2').find('input').val(arr[index].m2);

            result.find('.price').text(arr[index].price);
            result.find('.input-price').find('input').val(arr[index].price);

            result.find('.seats').text(arr[index].seats_min + '-' + arr[index].seats_max);
            result.find('.input-seats').find('input').val(arr[index].seats_min + '-' + arr[index].seats_max);

            result.find('.wc').text(arr[index].wc);
            result.find('.input-toilet').find('input').val(arr[index].wc);

            result.find('.conference').text(arr[index].conference);
            result.find('.input-conference').find('input').val(arr[index].conference);

            result.find('.kitchen').text(arr[index].kitchen);
            result.find('.input-kitchen').find('input').val(arr[index].kitchen);

            result.find('.img').attr('src', (arr[index].image));
            result.find('.input-blueprint').find('input').val(arr[index].image);
            result.find('.input-blueprint-mail').find('input').val(arr[index].image_mail);
            result.find('.input-acfattachedfile').find('input').val(arr[index].acfattachedfile);

            // resultOuter.html(result.clone());
            resultOuter.addClass('active');

        }

        // clicks

        el.find('.calc-wrapper').find('.switch').click(function () {
            var val = 0;
            var unit = 'seats';
            if (el.find('.calc-wrapper').hasClass('Kvm')) {
                val = parseFloat(el.find('.calc-wrapper').find('.m2 .val').text());
            } else {
                val = parseFloat(el.find('.calc-wrapper').find('.seats .val').text());
            }

            if (el.find('.calc-wrapper').hasClass('Kvm')) {
                unit = 'm2';
            }

            getClosest(val, unit, calc_results, true);

            el.find('.calc-wrapper').toggleClass("Kvm Platser");
            return false;
        });

        el.find('.calc-wrapper').find('button.add').click(function () {
            var val = 0;
            if (el.find('.calc-wrapper').hasClass('Kvm')) {
                val = parseFloat(el.find('.calc-wrapper').find('.m2 .val').text());
                if (val > 990) {
                    return false;
                }
                el.find('.calc-wrapper').find('.m2 .val').text(val + 10);
            } else {
                val = parseFloat(el.find('.calc-wrapper').find('.seats .val').text());
                el.find('.calc-wrapper').find('.seats .val').text(val + 1);
            }

            return false;
        });


        el.find('.calc-wrapper').find('button.subtract').click(function () {
            var val = 0;

            if (el.find('.calc-wrapper').hasClass('Kvm')) {
                val = parseFloat(el.find('.calc-wrapper').find('.m2 .val').text());
                if (val < 11) {
                    return;
                }
                el.find('.calc-wrapper').find('.m2 .val').text(val - 10);
            } else {
                val = parseFloat(el.find('.calc-wrapper').find('.seats .val').text());
                if (val < 2) {
                    return;
                }
                el.find('.calc-wrapper').find('.seats .val').text(val - 1);
            }
            return false;
        });


        // Handle press down

        var holdTimer;

        function startTrigger(e) {
            var $elem = $(this);
            $elem.data('mouseheld_timeout', setTimeout(function () {
                $elem.trigger('mouseheld');
                holdTimer = setInterval(function () {
                    $elem.trigger('click');
                }, 100);

            }, e.data));
        }

        function stopTrigger() {
            var $elem = $(this);
            clearInterval(holdTimer);
            clearTimeout($elem.data('mouseheld_timeout'));
        }

        var mouseheld = $.event.special.mouseheld = {
            setup: function (data) {
                // the first binding of a mouseheld event on an element will trigger this
                // lets bind our event handlers
                var $this = $(this);
                $this.bind('mousedown touchstart', +data || mouseheld.time, startTrigger);
                $this.bind('mouseleave mouseup touchend', stopTrigger);
            },
            teardown: function () {
                var $this = $(this);
                $this.unbind('mousedown touchstart', startTrigger);
                $this.unbind('mouseleave mouseup touchend', stopTrigger);
            },
            time: 750 // default to 750ms
        };

        el.find('.calc-wrapper').find('button.add, button.subtract').bind('mouseheld', function (e) {
            //console.log('Held', e);
        });

        el.find('.calc-wrapper').find('.show-price').click(function () {
            var val = 0;
            var unit = 'seats';
            if (el.find('.calc-wrapper').hasClass('Kvm')) {
                unit = 'm2';
                val = parseFloat(el.find('.calc-wrapper').find('.m2 .val').text());
            } else {
                val = parseFloat(el.find('.calc-wrapper').find('.seats .val').text());
            }

            getClosest(val, unit, calc_results, false);
        });

        $(document).on("click", '.price-calc-result .close', function () {
            resultOuter.removeClass("active");
            resultOuter.find('.download .toggle').removeClass("no-show");
            resultOuter.find('.download .form').removeClass("show");

            return false;
        });

        $(document).on("click", '.download .toggle', function () {
            $(this).addClass("no-show");
            resultOuter.find('.download .form').addClass("show");
            resultOuter.find('.download').removeClass("button-height");
            resultOuter.find('.download').addClass("form-height");
            return false;
        });

        // gravity callback not responding on first click.. :(
        var confirmationEventFired = false;

        jQuery(document).on('gform_confirmation_loaded', function (event, formId) {
            if(confirmationEventFired) {
                return false;
            }
            var msg = $('.result-container').find('#gform_' + formId).closest('.form').attr('data-msg');
            resultOuter.find('.result-container').find('#gform_' + formId).prepend('<div id="gform_confirmation_wrapper_6" class="gform_confirmation_wrapper "><div id="gform_confirmation_message_6" class="gform_confirmation_message_6 gform_confirmation_message">' + msg + '</div></div>');
            resultOuter.find('.result-container').find('#gform_' + formId).find('.gform_body, .gform_footer').hide();
            resultOuter.find('.result-container').find('#gform_' + formId).addClass('confirmed');
            confirmationEventFired = true;

        });




    });

});
