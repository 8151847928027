(function( $ ){
    $(document).ready(function () {

        $('.post-slider').each(function(){
            var el = $(this);

            var useAutoPlay = false;
            if(el.hasClass('auto-start')){
                useAutoPlay = true;
            }

            el.on("init.slick", function(event, slick) {
                if(el.find('.post-slider__post').length < 4){
                    el.find('.post-slider__post').addClass('slick-active');
                }
            });

            var desktopSlides = 3;
            var tabletSlides = 2;
            var mobileSlides = 1;

            if(el.attr('data-desktop-columns')){
                desktopSlides = parseFloat(el.attr('data-desktop-columns'));
            }

            if(el.attr('data-tablet-columns')){
                tabletSlides = parseFloat(el.attr('data-tablet-columns'));
            }

            if(el.attr('data-mobile-columns')){
                mobileSlides = parseFloat(el.attr('data-mobile-columns'));
            }

            var $slider = el.slick({
                centerMode: false,
                centerPadding: 0,
                dots: true,
                speed: 300,
                infinite: true,
                arrows: false,
                slidesToShow: desktopSlides,
                slidesToScroll: desktopSlides,
                autoplay: useAutoPlay,
                adaptiveHeight: true,
                focusOnSelect: false,
                responsive: [
                    {
                        breakpoint: 980,
                        settings: {
                            slidesToShow: tabletSlides,
                            slidesToScroll: tabletSlides,
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: mobileSlides,
                            slidesToScroll: mobileSlides,
                        }
                    }
                ]
            });


        });




        function openAjaxSlide (e, self) {
            e.stopPropagation();

            $('#post-slider__popup').removeClass('ajax-loaded');

            $('#post-slider__popup .inner .gallery').html('').removeClass('slick-slider slick-initialized');
            $('#post-slider__popup .inner .title').html('');
            $('#post-slider__popup .inner .city').html('');
            $('#post-slider__popup .inner .content').html('');

            lity('#post-slider__popup');

            $('.lity-close').html('');
            $('.lity-close').click(function () {
                $(this).addClass('closing');
            });

            $.post({
                url: window.urls.ajaxurl,
                data: {
                    action: 'PostSliderPopupInfo',
                    post_id: $(self).data('post-id')
                },
                success: function (response) {
                    if(response.success === true) {
                        $('#post-slider__popup, .lity-close').addClass('ajax-loaded');
                        var post = response.data.post;

                        $('#post-slider__popup .inner .title').html(post.post_title);
                        $('#post-slider__popup .inner .content').html(post.post_content);
                        $('#post-slider__popup .inner .city').html(post.city);
                        $('#post-slider__popup .inner .gallery').html('');


                        for(var i = 0; i < post.gallery.length; i++) {
                            $('#post-slider__popup .inner .gallery').append('<div class="gallery__image">'+'<div class="lazyload" data-bgset="'+post.gallery[i]+'">'+'</div>'+'</div>');
                        }
                        $('.lity #post-slider__popup .inner .gallery.slick-initialized').slick('unslick');
                        $('.lity #post-slider__popup .inner .gallery').slick({
                            dots: false,
                            speed: 300,
                            arrows: true,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplay: false,
                            adaptiveHeight: true,
                        });
                    }
                }
            });
        }

        $('.post-slider__post').not('.no-ajax').mousedown(function (e) {
          	var x = e.clientX;

            $(this).mouseup(function(e) {
                if($(this).hasClass('slick-active')) {
                    var newx = e.clientX;
                    if ( newx < x + 10 && newx > x - 10 ) {
                      openAjaxSlide(e, $(this).find('.post__image'));
                    }
                }
            });
        });

        $('button.filter-btn').click(function(){
           var el = $(this);

           var container = el.closest('.component-postslider');

            container.find('button.filter-btn').removeClass('active');

            el.addClass('active');
            container.find('.post-slider').slick('slickUnfilter');

            container.find('.post-slider__post').removeClass('active');

            container.find('.post-slider__post').each(function(){
                var slide = $(this);
                var arr = slide.attr('data-cat').split(',');
                if($.inArray(el.attr('data-id'), arr) !== -1) {
                    slide.addClass('active');
                }
            });


            if(el.attr('data-id') !== 'all'){
                container.find('.post-slider').slick('slickFilter','.active');
            }

            container.find('.post-slider').slick('slickGoTo', 1);

            // check if we have fewer than scroll, slick wont trigger active

            if($('.post-slider__post.active').length < 4){
                container.find('.post-slider__post').addClass('slick-active');
            }

        });

        // If any filter is set from start, trigger the filter
        $('button.filter-btn.active').trigger('click');




    });
})( jQuery );
