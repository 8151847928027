jQuery(document).ready(function ($) {
    $(".wpb_wrapper .component-button.float-btn").each(function () {
        var nextEl = $(this).next();
        if (!nextEl.hasClass('float-btn')) {
            nextEl.css("clear", "both");
        }
    });


    $(document).on('click', 'a.fancybox-iframe', function(){
        var url = $(this).attr('href');
        $.fancybox.open({
            src  : url,
            type : 'iframe',
            toolbar  : false,
            smallBtn : true,
            iframe : {
                preload : true
            }
        });
        return false;
    });


});
